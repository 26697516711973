.EditBookmark__form {
  text-align: left;
}

.EditBookmark__form input,
.EditBookmark__form textarea {
  display: block;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;
  /* minus side padding and border */
  width: calc(100% - 16px - 2px);
  padding: 5px 8px;
  border: 1px solid #ddd;
}

.EditBookmark__form label {
  margin-top: 10px;
}

.EditBookmark__buttons {
  text-align: center;
}

.EditBookmark__required {
  color: #a51414;
}

.EditBookmark__error p {
  color: #a51414;
  margin-bottom: 5px;
}
